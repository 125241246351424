<template>
  <div class="app-bugbounty">
    <div class="profile-content">
      <div class="profile card">
        <div class="profile-picture"></div>
        <span>Alonso Jauregui</span>
      </div>
      <div class="reward card">
        <div class="reward-data">
          <span>Pagos en este mes</span>
          <span>$0.00</span>
        </div>
        <div class="reward-data">
          <span>Total de pagos</span>
          <span>$0.00</span>
        </div>
        <div class="reward-data">
          <span>Próximos pagos</span>
          <span>$0.00</span>
        </div>
      </div>
    </div>
    <div class="activity-content">
      <div class="activity reports card">
        <img src="@/assets/img/laptop-bug.svg" alt="" />
        <h4>No se ah enviado ningun reporte</h4>
        <p>
          Envía reportes para empezar a generar puntos y así crecer en el
          programa de JAMA Security
        </p>
      </div>
      <div class="activity thanks card">
        <img src="@/assets/img/laptop-bug.svg" alt="" />
        <h4>No se ah enviado ningun reporte</h4>
        <p>
          Envía reportes para empezar a generar puntos y así crecer en el
          programa de JAMA Security
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "App"
};
</script>

<style lang="scss">
.app-bugbounty {
  padding: 60px;
  display: flex;
  justify-content: space-between;
  .card {
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 30px #00073614;
    margin: 0 0 30px 0;
  }
  .profile-content {
    height: 100%;
    .profile {
      width: 250px;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      .profile-picture {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        background-color: #efeff2;
      }
    }
    .reward {
      height: 30%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;
      padding: 30px;
      .reward-data {
        display: flex;
        flex-direction: column;
        span {
          &:nth-child(1) {
            color: #222ac3;
            font-weight: bold;
          }
        }
      }
    }
  }
  .activity-content {
    width: 100%;
    margin-left: 40px;
    height: 100%;
    .activity{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img{
            width: 100px;
        }
        h4{
            color: #222ac3; 
        }
    }
    .reports {
      height: 350px;
    }
    .thanks {
      height: 350px;
    }
  }
}
</style>
